<template>
  <b-modal
    id="alert-message"
    ref="alert-message"
    hide-footer
    :title="title"
    size="lg"
    centered
    hide-header-close
    :no-close-on-backdrop="false"
  >
  <div>
    <NewsContent :data="htmlDetails" />
  </div>

  <div class="mt-5 text-center">
    <button class="btn btn-info" @click="accept()">รับทราบ</button>
  </div>
  </b-modal>
</template>
<script>
import NewsContent from '@/views/news/components/NewsContent'

export default {
  name: 'AlertMessage',
  components: {
    NewsContent
  },
  computed: {
    title() {
      return this.$store.state.account?.news?.subject || null
    },
    htmlDetails() {
      return this.$store.state.account?.news?.htmlDetails || null
    },
    changedNews() {
      return (this.$store.state.account?.news?._id || null) + '-' + (this.$store.state.account?.news?.popupDisplay || null)
    },
    isNewsPopup() {
      return this.$store.state.account?.news?.popupDisplay !== 'close'
    }
  },
  watch: {
    changedNews() {
      if(this.isNewsPopup && this.title) {
        this.$refs['alert-message'].show()
      }
    }
  },
  methods: {
    accept() {
      this.$refs['alert-message'].hide()
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close')
    })
  }
}
</script>
<style lang="scss">
#alert-message {
  font-size: 110%;
  ul {
    list-style: decimal;

    li {
      margin-bottom: 10px;
    }
  }
}
</style>
